var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "phoneOrder", staticClass: "phoneOrder" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("span", [_vm._v("城际专车电召订单列表")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.phoneOrder },
            },
            [
              _c("i", { staticClass: "iconfont its_gy_xiadan" }),
              _vm._v(" 立即下单 "),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                model: _vm.form,
                "label-position": "left",
                "label-width": "85px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单编号：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入订单编号" },
                    model: {
                      value: _vm.form.orderId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "orderId",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.orderId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "下单人电话：" } },
                [
                  _c("el-input", {
                    attrs: { type: "number", placeholder: "请输入下单人电话" },
                    model: {
                      value: _vm.form.passengerPhone,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "passengerPhone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.passengerPhone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人电话：" } },
                [
                  _c("el-input", {
                    attrs: { type: "number", placeholder: "请输入联系人电话" },
                    model: {
                      value: _vm.form.orderPhone,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "orderPhone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.orderPhone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "订单状态：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "collapse-tags": true,
                        multiple: "",
                        clearable: "",
                        placeholder: "请选择订单状态",
                      },
                      model: {
                        value: _vm.form.orderStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "orderStatus", $$v)
                        },
                        expression: "form.orderStatus",
                      },
                    },
                    _vm._l(_vm.orderStatusList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "起点站：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "collapse-tags": true,
                        multiple: "",
                        clearable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.form.startStations,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "startStations", $$v)
                        },
                        expression: "form.startStations",
                      },
                    },
                    _vm._l(_vm.startList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "终点站：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "collapse-tags": true,
                        multiple: "",
                        clearable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.form.endStations,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "endStations", $$v)
                        },
                        expression: "form.endStations",
                      },
                    },
                    _vm._l(_vm.endList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "下单日期起：" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "选择时间",
                    },
                    model: {
                      value: _vm.form.startDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "startDate", $$v)
                      },
                      expression: "form.startDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "下单日期止：" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "选择时间",
                    },
                    model: {
                      value: _vm.form.endDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "endDate", $$v)
                      },
                      expression: "form.endDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发车日期起：" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "选择时间",
                    },
                    model: {
                      value: _vm.form.departureStartDateTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "departureStartDateTime", $$v)
                      },
                      expression: "form.departureStartDateTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发车日期止：" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "选择时间",
                    },
                    model: {
                      value: _vm.form.departureEndDateTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "departureEndDateTime", $$v)
                      },
                      expression: "form.departureEndDateTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属公司：" } },
                [
                  _c("companySelect", {
                    ref: "companySelect",
                    attrs: {
                      clearable: true,
                      "company-tree": _vm.companyList,
                      valuename: _vm.valueAddName,
                      value: _vm.valueAdd,
                    },
                    on: { getValue: _vm.getAddGroupId },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.onSearch("button")
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.exportFun()
                        },
                      },
                    },
                    [_vm._v("导出")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "small" },
                      on: { click: _vm.reset },
                    },
                    [_vm._v("重置筛选条件")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("p", { staticStyle: { padding: "10px 0" } }),
      _c("Table", {
        attrs: {
          "table-data": _vm.tableData,
          "title-name": _vm.titleName,
          operation: true,
          "col-class-type": true,
          "table-height": _vm.tableHeight,
          "operation-width": "220",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  [
                    scope.scopeRow.orderStatus == 10
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#ff8627" },
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.payMoney(scope.scopeRow)
                              },
                            },
                          },
                          [_vm._v("去支付")]
                        )
                      : _vm._e(),
                    scope.scopeRow.orderStatus == (40 || 60 || 70 || 130)
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.referCode(scope.scopeRow)
                              },
                            },
                          },
                          [_vm._v("乘车码")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small", sort: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.onHistoryDetail(scope.scopeRow)
                          },
                        },
                      },
                      [_vm._v("调度详情")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small", sort: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.orderDetial(scope.scopeRow)
                          },
                        },
                      },
                      [_vm._v("订单详情")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage,
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.onCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "调度历史详情",
                visible: _vm.dialogVisible,
                width: "60%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.historyData, stripe: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "orderId",
                      label: "订单号",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "scheduleType",
                      label: "调度类型",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.scheduleType == 1
                                      ? "派车"
                                      : "派司机"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      280858640
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "userName",
                      label: "调度人",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "scheduleContent",
                      label: "调度内容",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "调度时间",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看乘车码",
            visible: _vm.showRideCode,
            width: "25%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showRideCode = $event
            },
          },
        },
        [
          _c("div", { staticClass: "currentCode" }, [
            _c("div", { staticStyle: { display: "flex" } }, [
              _c("div", { staticStyle: { "line-height": "67px" } }, [
                _vm._v("当前订单的乘车码为："),
              ]),
              _c(
                "div",
                { staticStyle: { "font-size": "40px", display: "block" } },
                [_vm._v(_vm._s(_vm.rideCode))]
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showRideCode = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }