<template>
  <!-- 电召下单 -->
  <div ref="phoneOrder" class="phoneOrder">
    <div class="header">
      <span>城际专车电召订单列表</span>
      <el-button type="primary" size="small" @click="phoneOrder">
        <i class="iconfont its_gy_xiadan"></i>
        立即下单
      </el-button>
    </div>
    <div ref="form" class="form-area">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="85px"
      >
        <el-form-item label="订单编号：">
          <el-input
            v-model.trim="form.orderId"
            placeholder="请输入订单编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="下单人电话：">
          <el-input
            v-model.trim="form.passengerPhone"
            type="number"
            placeholder="请输入下单人电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人电话：">
          <el-input
            v-model.trim="form.orderPhone"
            type="number"
            placeholder="请输入联系人电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单状态：">
          <el-select
            v-model="form.orderStatus"
            :collapse-tags="true"
            multiple
            clearable
            placeholder="请选择订单状态"
          >
            <el-option
              v-for="item in orderStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="起点站：">
          <el-select
            v-model="form.startStations"
            :collapse-tags="true"
            multiple
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in startList"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="终点站：">
          <el-select
            v-model="form.endStations"
            :collapse-tags="true"
            multiple
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in endList"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单日期起：">
          <el-date-picker
            v-model="form.startDate"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="下单日期止：">
          <el-date-picker
            v-model="form.endDate"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="发车日期起：">
          <el-date-picker
            v-model="form.departureStartDateTime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="发车日期止：">
          <el-date-picker
            v-model="form.departureEndDateTime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="所属公司：">
          <companySelect
            ref="companySelect"
            :clearable="true"
            :company-tree="companyList"
            :valuename="valueAddName"
            :value="valueAdd"
            @getValue="getAddGroupId"
          ></companySelect>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSearch('button')"
            >查询</el-button
          >
          <el-button type="default" size="small" @click="exportFun()"
            >导出</el-button
          >
          <el-button type="text" size="small" @click="reset"
            >重置筛选条件</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <p style="padding: 10px 0"></p>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :operation="true"
      :col-class-type="true"
      :table-height="tableHeight"
      operation-width="220"
    >
      <template slot-scope="scope">
        <div>
          <el-button
            v-if="scope.scopeRow.orderStatus == 10"
            type="text"
            size="small"
            sort="primary"
            style="color: #ff8627"
            @click="payMoney(scope.scopeRow)"
            >去支付</el-button
          >
          <!-- <el-button
            v-if="scope.scopeRow.orderStatus == 110"
            type="text"
            size="small"
            sort="primary"
            >已取消</el-button
          > -->
          <el-button
            v-if="scope.scopeRow.orderStatus == (40 || 60 || 70 || 130)"
            type="text"
            size="small"
            sort="primary"
            @click="referCode(scope.scopeRow)"
            >乘车码</el-button
          >
          <el-button
            type="text"
            size="small"
            sort="primary"
            @click="onHistoryDetail(scope.scopeRow)"
            >调度详情</el-button
          >
          <el-button
            type="text"
            size="small"
            sort="primary"
            @click="orderDetial(scope.scopeRow)"
            >订单详情</el-button
          >
        </div>
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <el-dialog
      v-if="dialogVisible"
      title="调度历史详情"
      :visible.sync="dialogVisible"
      width="60%"
    >
      <el-table :data="historyData" stripe>
        <el-table-column
          prop="orderId"
          label="订单号"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="scheduleType"
          label="调度类型"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.scheduleType == 1 ? "派车" : "派司机" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="userName"
          label="调度人"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="scheduleContent"
          label="调度内容"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="调度时间"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="查看乘车码" :visible.sync="showRideCode" width="25%">
      <div class="currentCode">
        <div style="display: flex">
          <div style="line-height: 67px">当前订单的乘车码为：</div>
          <div style="font-size: 40px; display: block">{{ rideCode }}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="showRideCode = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import companySelect from "@/components/treeSelect/companySelect.vue";
import {
  getCompanyTree,
  getPhoneOrderListAPI,
  phoneOrderListExpAPI,
  startStationAPI,
  endStationAPI,
  queryScheduleHistoryDetail,
  checkCodeAPI,
} from "@/api/lib/api.js";
export default {
  components: { companySelect },
  data() {
    return {
      titleName: [
        {
          title: "订单编号",
          props: "orderId",
        },
        {
          title: "订单类型",
          props: "purchaseMethod",
          SpecialJudgment: (res) => {
            return res == 0 ? "乘客购票（默认）" : "后台购票";
          },
        },
        {
          title: "下单人姓名",
          props: "passengerName",
          width: 100,
        },
        {
          title: "下单人手机",
          props: "passengerPhone",
          width: 100,
        },
        {
          title: "联系人手机",
          props: "orderPhone",
          width: 100,
        },
        {
          title: "订单乘客数量",
          props: "passengerCount",
          width: 110,
        },
        {
          title: "有效乘客数量",
          props: "validPassengersNum",
          width: 110,
        },
        {
          title: "携童数",
          props: "carryingChildrenNum",
        },
        {
          title: "票价",
          props: "ticketPricesPer",
        },
        {
          title: "服务费",
          props: "servicePricesPer",
        },
        {
          title: "支付金额",
          props: "totalPrices",
        },
        {
          title: "所属公司",
          props: "companyName",
        },
        {
          title: "线路",
          props: "lineName",
        },
        {
          title: "发车时间",
          SpecialJudgment: (res) => {
            return res.departureDate + " " + res.departureTime;
          },
        },
        {
          title: "上车点",
          props: "startAddress",
        },
        {
          title: "下车点",
          props: "endAddress",
        },
        {
          title: "下单时间",
          props: "createTime",
        },
        {
          title: "订单状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            return this.orderStatusList.find((t) => t.value == res).label;
          },
        },
        {
          title: "退票金额",
          props: "refundTicketPrices",
        },
        {
          title: "退票手续费",
          props: "refundTicketServicePrices",
          width: 100,
        },
        {
          title: "退票时间",
          props: "refundTime",
        },
      ],
      tableData: [],
      form: {
        currentPage: 1,
        pageSize: 10,
        orderId: null,
        passengerPhone: "",
        orderPhone: "",
        startStations: [],
        endStations: [],
        startDate: "",
        endDate: "",
        departureStartDateTime: "",
        departureEndDateTime: "",
        orderStatus: "",
        companyIds: [],
      },
      startList: [],
      endList: [],
      total: 0,
      tableHeight: 0,
      orderStatusList: [
        { value: 10, label: "待支付" },
        { value: 20, label: "待配车" },
        { value: 30, label: "已配车（待派单）" },
        { value: 40, label: "已派单（待接驾）" },
        { value: 50, label: "司机申请取消接驾" },
        { value: 60, label: "已接驾" },
        { value: 70, label: "已完成" },
        { value: 80, label: "取消接驾" },
        { value: 110, label: "订单取消" },
        { value: 120, label: "已退票" },
        { value: 130, label: "已改签" },
        { value: null, label: "" },
      ],
      companyList: [],
      valueAddName: null,
      valueAdd: null,
      historyData: [],
      dialogVisible: false,
      showRideCode: false,
      rideCode: "",
    };
  },
  methods: {
    // 选择起点站
    getStartStation() {
      startStationAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.startList = [];
          res.data.map((item, index) => {
            this.startList.push({ value: index, label: item });
          });
        }
      });
    },
    // 选择终点站
    getEndStation() {
      endStationAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.endList = [];
          res.data.map((item, index) => {
            this.endList.push({ value: index, label: item });
          });
        }
      });
    },
    // 当前分页改变
    onCurrentChange(val) {
      this.form.currentPage = val;
      this.onSearch();
    },
    // 分页大小改变
    onSizeChange(val) {
      this.form.pageSize = val;
      this.form.currentPage = 1;
      this.onSearch();
      this.computeHeight();
    },
    // 查看乘车码
    referCode(scope) {
      this.showRideCode = true;
      checkCodeAPI({ orderId: scope.orderId }).then((res) => {
        if (res.code == "SUCCESS") {
          this.rideCode = res.data;
        }
      });
    },
    // 订单详情
    orderDetial(scope) {
      this.$router.push({
        path: "/projectTravel/phoneOrder/detial",
        query: { id: scope.orderId },
      });
    },
    // 调度详情
    onHistoryDetail(scope) {
      queryScheduleHistoryDetail({ orderId: scope.orderId }).then((res) => {
        if (res.code === "SUCCESS") {
          if (res.data.length === 0) {
            this.$message.warning("暂无调度数据！");
          } else {
            this.historyData = res.data;
            this.dialogVisible = true;
          }
        }
      });
    },
    // 去支付
    payMoney(scope) {
      this.$router.push({
        path: "/projectTravel/phoneOrder/detial",
        query: { id: scope.orderId },
      });
    },
    // 查询
    onSearch(type) {
      if (type === "button") {
        this.form.currentPage = 1;
      }
      getPhoneOrderListAPI(this.form).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 点击电召下单
    phoneOrder() {
      this.$router.push("/projectTravel/phoneOrder/detial");
    },
    // 导出
    exportFun() {
      phoneOrderListExpAPI(this.form).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "电召下单订单 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");
        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    // 获取企业树
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    // 获得企业id
    getAddGroupId(v) {
      if (v !== null) {
        this.form.companyIds = [v];
      } else {
        this.form.companyIds = [];
      }
    },
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.phoneOrder.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight - 16 - 16 - formHeight - paginationHeight - 10 - 100;
    },
    // 重置筛选条件
    reset() {
      this.$refs.companySelect.clearHandle();
      this.form = {
        currentPage: 1,
        orderId: null,
        pageSize: 10,
        passengerPhone: "",
        orderPhone: "",
        startStations: [],
        endStations: [],
        startDate: "",
        endDate: "",
        departureStartDateTime: "",
        departureEndDateTime: "",
        orderStatus: "",
        companyIds: [],
      };
    },
  },
  mounted() {
    this.getStartStation();
    this.getEndStation();
    this.onSearch();
    this.getCompanyList();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
};
</script>

<style lang="scss" scoped>
.phoneOrder {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  .header {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .currentCode {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
  }
}
</style>
